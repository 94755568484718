import { useEffect, useState } from 'react';
import './index.scss'
import ReactApexChart from 'react-apexcharts';


const PieChart = () => {

    const [showChart, setShowChart] = useState(false)
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowChart(true);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    const opts:any = {
        chart:{
            id:'2',
            zoom: {
                enabled: false
            },
            type:"pie",
        },
        series: [44, 55, 13],
        dataLabels: {
            enabled:false
        },
        tooltip:{
            enabled:false
        },
        labels: ['Apples', 'Oranges', 'Bananas'],
        colors:['#164B60', '#859FAA', '#4E7685'],
        plotOptions: {
            startAngle: 0,
            endAngle: 360,
            pie: {
                expandOnClick: false,
                donut: {
                    size: '100%',
                    background:'#EEE2DE',
                    labels: {
                        show: false,
                    },
                    value: {
                        color:['#164B60', '#859FAA', '#4E7685'],
                    }
                }
            }
        },
        yaxis:{
            labels:{
                show:false
            }
        },
        legend:{
            show:false
        }
    }


    return(
        <div className="bg-col-white rounded-25 p-3 m-2 pie-chart position-relative">
            <h2 className="text-12 font-weight-5 mb-0">Casual Leave</h2>
            <span className="text-8 font-weight-2 font-1">Used So far</span>
            {showChart?<><ReactApexChart className="hide-mb-pie" options={opts} series={opts.series} type="pie" height={100} width={"80%"} />
            <ReactApexChart className="show-mb-pie move-up" options={opts} series={opts.series} type="pie" height={85} width={"80%"}/></>:<div></div>}
        </div>
    )
}

export default PieChart