import {Link, useLocation} from "react-router-dom"
import {ReactComponent as Logo} from "../../../assets/logos/logo.svg"
import {ReactComponent as Global} from "../../../assets/logos/global-edit.svg"
import { useState, useEffect, CSSProperties } from 'react';
import {ReactComponent as Polygon} from "../../../assets/logos/polygon.svg"
import "./index.scss"
import {motion} from "framer-motion"
import { useContext } from "react";
import { MenuContext } from "../../../app";
import { Tooltip } from "@mui/material";
import { BaseBtn } from "../../../styled-components";
import { RiArrowDropDownLine } from "react-icons/ri";

const Navbar = () => {

    const props = useContext(MenuContext)
    const {setIsOpen, setIsOn, isOn, setIsProductOpen, isProductOpen, setModules, setIsMobProductOpen} = props
    const location = useLocation();
    const showHeader = !(
        location.pathname === "/book-a-demo" ||
        location.pathname === "/sign-up" ||
        location.pathname === "/blogs" ||
        location.pathname.includes("/blog/") ||
        location.pathname === "/login" ||
        location.pathname.includes("/account/verify/") ||
        location.pathname.includes("/account/activate/")
      );


    const [isContainerVisible, setIsContainerVisible] = useState(true);

    const toggleSwitch = () => setIsOn(!isOn);

    
    useEffect(() => {
        const handleScroll = () => {
            const container = document.getElementById('themeContainer');
            if (container) {
                const containerTop = container.getBoundingClientRect().top;
                setIsContainerVisible(containerTop >= 0 && containerTop < window.innerHeight);
            }
        };

        const rootContainer = document.getElementById('root');
        
        if (rootContainer) {
            rootContainer.addEventListener('scroll', handleScroll);
        }
        
        return () => {
            if (rootContainer) {
                rootContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    
    const containerStyle:CSSProperties = isContainerVisible ? { position: 'relative' } : { position: 'fixed', top: 0, zIndex:5, height:'70px', backgroundColor:'#ffffff', boxShadow:'0 2px 5px -2px rgb(228 228 228)' };
    
    const spring = {
        type: "spring",
        stiffness: 700,
        damping: 30
    };

    const empty = <div></div>


    const clickLogo = () => {
        setIsOpen(false)
        setIsProductOpen(false)
        // setModules(data2)
        setIsMobProductOpen(false)
    }

    const clickLinks = () => {
        setIsProductOpen(false)
        // setModules(data2)
    }

    return(
        <>
        {showHeader ?(<>
        <div className="nav-main">
        <div className="d-flex flex-column justify-content-center align-items-center align-self-center contrast-cont"> 
            <div id="themeContainer" className="d-flex flex-row justify-content-between align-items-center inner-contrast align-self-center">
                <p className="text-10 col-secondary">"Chennai Roots, Silicon Valley Vision: Welcome to Walkzero's Next Gen HRMS & Payroll." #IndiaSaas #SaasBoomi</p>
                <div className="text-12 d-flex flex-row align-items-center">
                    <div className="d-flex align-items-center">
                        <span className="text-10 col-black-800">High Contrast</span>
                        <div className="ms-1 switch" data-ison={isOn} onClick={toggleSwitch}>
                            <motion.div style={{backgroundColor:isOn?"#1A2D48":"#F45600"}} className="handle" layout transition={spring} />
                        </div>
                    </div>
                    <div className="d-flex align-items-center m-l-30">
                        <span className="text-10 col-black-800 me-1">English</span>
                        <Global width={15} height={15}/>
                    </div>
                </div>
            </div>
        </div>
        <div style={containerStyle} className="nav-bar">
            <motion.div animate={{y:isContainerVisible?0:[-100, 0],
            transition:{
                staggerChildren: 0.08,
                delayChildren: 0.04 * 1,
                duration: 1,
                type:"spring",
                bounce:0.4
            },}} className="nav-container">
                <Link to="/" onClick={clickLogo}><Logo width={"73%"} className="logo-height"/></Link>
                <div className="link-container">
                    <span className="link icon-left-arrows" onMouseEnter={() => setIsProductOpen(true)}>Product <RiArrowDropDownLine style={{rotate:isProductOpen?"180deg":"0deg", transition:"all 0.3s ease"}} className="text-20" /></span>
                    <Tooltip title="Coming Soon" arrow>
                    <Link to="" className="link" onMouseEnter={() => setIsProductOpen(false)} onClick={clickLinks}>Pricing</Link>
                    </Tooltip>
                    <Link to="/about-us" className="link" onMouseEnter={() => setIsProductOpen(false)} onClick={clickLinks}>About us</Link>
                    <Link to="/blogs" className="link d-flex flex-column align-items-center" onMouseEnter={() => setIsProductOpen(false)} onClick={clickLinks}><Polygon height={5} width={6} /><span className="m-t--5">Blogs</span></Link>
                    {/* <Tooltip title="Coming Soon" arrow> */}
                    <Link to="/login" className="link" onMouseEnter={() => setIsProductOpen(false)} onClick={clickLinks}>Login</Link>
                    {/* </Tooltip> */}
                    <Link to="/sign-up"><BaseBtn isOn={isOn} className="get-started-btn text-12" onMouseEnter={() => setIsProductOpen(false)} type="button" onClick={clickLinks}>Get Started</BaseBtn></Link>
                </div>
                <div className="d-flex flex-row justify-content-center align-items-center mt-4 mb-4 show-lang"> 
                    <div className="d-flex align-items-center">
                        <span className="text-10 col-black-800">Contrast</span>
                        <div className="ms-1 switch" data-ison={isOn} onClick={toggleSwitch}>
                            <motion.div style={{backgroundColor:isOn?"#1A2D48":"#F45600"}} className="handle" layout transition={spring} />
                        </div>
                    </div>
                <div className="d-flex align-items-center m-l-30 ">
                    <span className="text-10 col-black-800 me-1"> EN</span>
                    <Global width={15} height={15}/>
                </div>
                </div>
            </motion.div>
        </div>
        </div></>):empty}
        </>
    )
}

export default Navbar