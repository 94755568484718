import './index.scss'

import { useContext } from "react"
import { Link } from "react-router-dom"

import {ReactComponent as ButtonLogo} from "../../../assets/logos/button-logo.svg"
import { BaseBtn} from "../../../styled-components"
import { MenuContext } from "../../../app"
import PayrollSteps from '../menu-components/process-steps'

const PayrollProcess = () => {

    const props = useContext(MenuContext);
    const {isOn} = props

    return(
        <>
        <div className='d-flex flex-row justify-content-center align-items-center w-100 mt-5'>
            <div className='d-flex flex-column justify-content-center align-items-center w-90'>
                <div className='payroll-process-1 d-flex flex-column justify-content-center align-items-center'>
                    <p className='m-0 text-32 font-weight-6 sora'><span className='col-secondary'>Payroll</span> Process</p>
                    <p className='lexend-deca text-14 font-weight-3 text-center'>The payroll process involves calculating and distributing employee wages, including base pay, bonuses, and deductions such as taxes and benefits. This process ensures employees are paid accurately and on time, often through direct deposit or checks. After payments are made, records are updated, and tax filings are completed to maintain compliance with legal requirements.</p>
                    <div style={{marginTop:"15px"}} className="position-relative mob-width">
                        <Link to="/sign-up" className="decoration">
                        <BaseBtn isOn={isOn} className="position-relative border-0 p-y-10 rounded-10 d-flex flex-row justify-content-center align-items-center w-100 btn-4">
                            <ButtonLogo className="me-1 logo-up" /> 
                            <span className="col-white font-bold text-14 font-weight-700 z-index-0">Sign up for Free</span>
                        </BaseBtn>
                        </Link>
                    </div>
                </div>
                <div className='d-flex flex-row justify-content-center align-items-center w-100 payroll-steps' style={{paddingRight:"50px", margin:"30px 0 50px 0"}}>
                    <PayrollSteps/>
                </div>
            </div>
        </div>
        </>
    )
}
export default PayrollProcess