import { SVGProps, useRef } from "react"
import {motion, useInView} from "framer-motion"
import '../../../../styles/fonts.scss';

import './index.scss'

const PayrollSteps = (props: SVGProps<SVGSVGElement>) => {

    const ref = useRef(null)
    const isInView = useInView(ref, { once: false , amount: 0.5});
  
  return (
    <>
    <svg
    {...props}
      xmlns="http://www.w3.org/2000/svg"
      // width="869"
      // height="334"
      fill="none"
      viewBox="0 0 869 334"
      className="process-steps"
    >
      <g id="payrollSteps">
        <text
          id="Step 3"
          fill="#F45600"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontFamily="Lexend-Deca"
          fontSize="18"
          fontWeight="bold"
          letterSpacing="0em"
        >
          <tspan x="736" y="19.3">
            Step 3
          </tspan>
        </text>
        <text
          id="Step 2"
          fill="#1F3549"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontFamily="Lexend-Deca"
          fontSize="18"
          fontWeight="bold"
          letterSpacing="0em"
        >
          <tspan x="425" y="19.3">
            Step 2
          </tspan>
        </text>
        <text
          id="Step1"
          fill="#F45600"
          xmlSpace="preserve"
          style={{ whiteSpace: "pre" }}
          fontFamily="Lexend-Deca"
          fontSize="18"
          fontWeight="bold"
          letterSpacing="0em"
        >
          <tspan x="113" y="19.3">
            Step1
          </tspan>
        </text>
        <g id="payroll-new">
          <motion.text
            id="Payroll Process"
            fill="#1F3549"
            xmlSpace="preserve"
            style={{ whiteSpace: "pre" }}
            fontFamily="Lexend-Deca"
            fontSize="14"
            fontWeight="600"
            letterSpacing="0em"
            initial={{x:-100,opacity:0}}
            animate={isInView?{x:10,opacity:1}:{}}
            transition={{duration:1, type:"tween",delay:.5}}
          >
            <tspan x="399" y="141.25">
              Payroll Process
            </tspan>
          </motion.text>
          <g id="cover-data">
            <g id="process-data">
              <path
                id="step-2-dasharray"
                fill="#57B757"
                d="M612.135 193.22a1.5 1.5 0 002.731 1.243l-2.731-1.243zM694.31 76.983l.802 1.267h.002l-.804-1.267zm173.278 19.164l-5.331-16.479-11.606 12.857 16.937 3.622zm-252.722 98.316c10.335-22.704 20.803-45.689 33.515-66.066 12.707-20.368 27.601-38.037 46.731-50.147l-1.605-2.535c-19.649 12.44-34.832 30.513-47.671 51.094-12.834 20.572-23.382 43.742-33.701 66.411l2.731 1.243zm80.248-116.214C717.007 64.35 742.403 57 768.536 57v-3c-26.698 0-52.652 7.508-75.03 21.716l1.608 2.533zM768.536 57c53.438 0 85.595 29.02 97.829 40.064l2.011-2.226C856.036 83.698 823.154 54 768.536 54v3z"
              ></path>
              <motion.path
                id="dotted-step-2"
                stroke="#57B757"
                // strokeDasharray="6 6"
                strokeLinecap="round"
                strokeWidth="3"
                ref={ref}
                initial={{ strokeDasharray: "6 6", strokeDashoffset: 0 }} 
                animate={isInView ? { strokeDashoffset: 0 } : { strokeDashoffset: 100 }} 
                transition={{
                duration: 3,
                ease: "easeInOut",
                type:"tween"                    
              }}
                d="M341 253.057c6.645 15.145 15.964 28.787 27.711 40.537 25.078 25.084 58.427 38.891 93.915 38.891 25.222 0 49.745-7.099 70.934-20.536 36.558-23.129 57.336-68.79 77.436-112.949"
              ></motion.path>
              <motion.path
                id="step-1-dasharray"
                fill="#57B757"
                strokeDasharray={1234}
                strokeDashoffset={1234}
                initial={{ strokeDasharray: 0 }}
                animate={isInView?{ strokeDasharray: 1234 }:{}}
                transition={{ duration: 2, ease: "easeInOut" }}
                d="M567.603 131.08l-.262-17.318-14.867 8.886 15.129 8.432zM146.5 330.827a1.5 1.5 0 000 3v-3zM561.925 118.66a134.525 134.525 0 00-16.712-20.33l-2.121 2.122a131.584 131.584 0 0116.34 19.876l2.493-1.668zm-16.712-20.33C519.834 72.965 486.077 59 450.165 59v3c35.118 0 68.111 13.65 92.927 38.452l2.121-2.122zM450.165 59c-25.504 0-50.345 7.179-71.813 20.748l1.603 2.536C400.941 69.019 425.226 62 450.165 62v-3zm-71.813 20.748c-18.524 11.706-32.994 29.081-45.332 48.977-12.335 19.89-22.599 42.404-32.646 64.437l2.73 1.245c10.063-22.069 20.249-44.402 32.465-64.101 12.213-19.694 26.394-36.652 44.386-48.022l-1.603-2.536zm-77.978 113.414l-.082.181 2.73 1.244.082-.18-2.73-1.245zm-.082.181c-20.724 45.509-41.951 91.95-80.29 116.234l1.606 2.534c39.239-24.854 60.795-72.246 81.414-117.524l-2.73-1.244zm-80.29 116.234c-21.932 13.901-47.348 21.25-73.502 21.25v3c26.718 0 52.692-7.508 75.108-21.716l-1.606-2.534z"
              ></motion.path>
              <motion.path
                id="dotted-step-1"
                stroke="#57B757"
                // strokeDasharray="6 6"
                strokeLinecap="round"
                strokeWidth="3"
                ref={ref}
                initial={{ strokeDasharray: "6 6", strokeDashoffset: 0 }} 
                animate={isInView ? { strokeDashoffset: 0 } : { strokeDashoffset: -100 }} 
                transition={{
                duration: 3,
                ease: "easeInOut",
                type:"tween"                    
              }}
                d="M141.078 332c-37.143 0-72.061-14.445-98.353-40.702C16.453 265.041 2 230.142 2 193c0-37.122 14.453-72.021 40.725-98.298C69.017 68.445 103.935 54 141.078 54c33.807 0 66.399 12.264 91.764 34.529"
              ></motion.path>
              <motion.text
                id="Post-Payroll Process"
                fill="#F45600"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Lexend-Deca"
                fontSize="14"
                fontWeight="600"
                letterSpacing="0em"
                initial={{x:-100,opacity:0}}
                animate={isInView?{x:10,opacity:1}:{}}
                transition={{duration:1, type:"tween",delay:1}}
              >
                <tspan x="689" y="141.25">
                  Post-Payroll Process
                </tspan>
              </motion.text>
              <motion.text
                id="Statutory payables report"
                fill="#3C3C3C"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Lexend-Deca"
                fontSize="14"
                fontWeight="300"
                letterSpacing="0em"
                initial={{x:-100,opacity:0}}
                animate={isInView?{x:0,opacity:1}:{}}
                transition={{duration:1, type:"tween",delay:1}}
              >
                <tspan x="686.29" y="174.25">
                • Statutory payables report
                </tspan>
              </motion.text>
              <motion.text
                id="Employee payouts"
                fill="#3C3C3C"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Lexend-Deca"
                fontSize="14"
                fontWeight="300"
                letterSpacing="0em"
                initial={{x:-100,opacity:0}}
                animate={isInView?{x:0,opacity:1}:{}}
                transition={{duration:1, type:"tween",delay:1}}
              >
                <tspan x="686.129" y="218.25">
                • Employee payouts
                </tspan>
              </motion.text>
              <motion.text
                id="Payroll Reports"
                fill="#3C3C3C"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Lexend-Deca"
                fontSize="14"
                fontWeight="300"
                letterSpacing="0em"
                initial={{x:-100,opacity:0}}
                animate={isInView?{x:0,opacity:1}:{}}
                transition={{duration:1, type:"tween",delay:1}}
              >
                <tspan x="686.895" y="262.25">
                • Payroll Reports
                </tspan>
              </motion.text>
              <motion.text
                id="Payroll Forecast"
                fill="#3C3C3C"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Lexend-Deca"
                fontSize="14"
                fontWeight="300"
                letterSpacing="0em"
                initial={{x:-100,opacity:0}}
                animate={isInView?{x:0,opacity:1}:{}}
                transition={{duration:1, type:"tween",delay:.5}}
              >
                <tspan x="384.145" y="174.25">
                • Payroll Forecast
                </tspan>
              </motion.text>
              <motion.text
                id="Time off and attendance"
                fill="#3C3C3C"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Lexend-Deca"
                fontSize="14"
                fontWeight="300"
                letterSpacing="0em"
                initial={{x:-100,opacity:0}}
                animate={isInView?{x:0,opacity:1}:{}}
                transition={{duration:1, type:"tween",delay:.5}}
              >
                <tspan x="384.498" y="218.25">
                • Time off and attendance
                </tspan>
              </motion.text>
              <motion.text
                id="Review &amp; Calculations"
                fill="#3C3C3C"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Lexend-Deca"
                fontSize="14"
                fontWeight="300"
                letterSpacing="0em"
                initial={{x:-100,opacity:0}}
                animate={isInView?{x:0,opacity:1}:{}}
                transition={{duration:1, type:"tween",delay:.5}}
              >
                <tspan x="384.283" y="262.25">
                • Review &amp; Calculations
                </tspan>
              </motion.text>
              <motion.text
                id="Payroll Settings"
                fill="#F45600"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Lexend-Deca"
                fontSize="14"
                fontWeight="600"
                letterSpacing="0em"
                initial={{x:-100,opacity:0}}
                animate={isInView?{x:-20,opacity:1}:{}}
                transition={{duration:1, type:"tween"}}
              >
                <tspan x="90" y="136.25">
                  Payroll Settings
                </tspan>
              </motion.text>
              <motion.text
                id="Define pay frequency"
                fill="#3C3C3C"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Lexend-Deca"
                fontSize="14"
                fontWeight="300"
                letterSpacing="0em"
                initial={{x:-100,opacity:0}}
                animate={isInView?{x:-20,opacity:1}:{}}
                transition={{duration:1, type:"tween"}}
              >
                <tspan x="60.234" y="174.25">
                • Define pay frequency
                </tspan>
              </motion.text>
              <motion.text
                id="Create Salary structure"
                fill="#3C3C3C"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Lexend-Deca"
                fontSize="14"
                fontWeight="300"
                letterSpacing="0em"
                initial={{x:-100,opacity:0}}
                animate={isInView?{x:-20,opacity:1}:{}}
                transition={{duration:1, type:"tween"}}
              >
                <tspan x="60.355" y="218.25">
                • Create Salary structure
                </tspan>
              </motion.text>
              <motion.text
                id="Enable applicable statutories"
                fill="#3C3C3C"
                xmlSpace="preserve"
                style={{ whiteSpace: "pre" }}
                fontFamily="Lexend-Deca"
                fontSize="14"
                fontWeight="300"
                letterSpacing="0em"
                initial={{x:-100,opacity:0}}
                animate={isInView?{x:-20,opacity:1}:{}}
                transition={{duration:1, type:"tween"}}
              >
                <tspan x="60.071" y="262.25">
                • Enable applicable statutories
                </tspan>
              </motion.text>
            </g>
          </g>
        </g>
      </g>
    </svg>
    </>
  )
}

export default PayrollSteps;