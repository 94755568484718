import "./index.scss"
import SignupButton from "../../layout/button"
import {ReactComponent as Left} from "../../../assets/logos/logo-left.svg"
import {ReactComponent as TopW} from "../../../assets/logos/top-w-curve.svg"
import {ReactComponent as BottomW} from "../../../assets/logos/bottom-w-curve.svg"
import Charts from "../charts"
import {motion} from "framer-motion"
import { useState, useEffect } from "react";


const data = [
  {id:"l1", name:"Unlock Potential"},
  {id:"l2", name:"Enhance Productivity"},
  {id:"l3", name:"Enhance Efficiency"},
  {id:"l4", name:"Enhance Excellence"},
  {id:"l5", name:"Enhance Performance"},
]


const Bussiness = () => {

    const [scrollPosition, setScrollPosition] = useState(0);
    const [list, setList] = useState(data)

    useEffect(() => {
        const interval = setInterval(() => {
          setScrollPosition(prevPosition => prevPosition - 30);
          setList(prevList => [prevList[1], prevList[2], prevList[3], prevList[4], prevList[0]])
        }, 2000);
    
        return () => clearInterval(interval);
      }, []); 

    const childTop = {
      visible: {
        x:0,
        y:0,
        transition: {
          duration: 1,
          delay: 1,
          type:'tween'
        }
      },
      hidden: {
        x:0,
        y:-500,
      }
    }

    const childBottom = {
      visible: {
        x:0,
        y:0,
        transition: {
          duration: 1,
          delay: 1,
          type:'tween'
        }
      },
      hidden: {
        x:0,
        y:210,
      }
    }

    const child2 = {
      visible: {
        x:0,
        y:0,
        rotate:0,
        transition: {
          duration: 1.5,
          type:'tween'
        }
      },
      hidden: {
        x:-1700,
        y:350,
        rotate:90,
      }
    }

    return(
        <motion.div className="position-relative bg-col-container-bg d-flex flex-column justify-content-center align-items-center w-100 pb-5">
            <div className="d-flex flex-row justify-content-center align-items-center align-self-center pt-50 pb-50 res">
                <motion.div initial={{opacity:0}} animate={{opacity:1, transition:{duration:1, type:"tween", ease:"easeInOut"}}} className="w-55 mt-4 first z-index-1">
                    <motion.div className="col-quaternary font-weight-5 text-12 m-b-8 text-uppercase cent">#NextGeneration #HRMS #HCM #Payroll #AllinOne</motion.div>
                    <motion.h2 className="col-container-font m-b-5 hide-mb h1-size">Empowering Business,</motion.h2>
                    <motion.h2 className="col-container-font m-b-5 show-mb h1-size">Empowering</motion.h2>
                    <motion.h2 className="col-container-font mb-0 flow hide-mb h1-size"> Enriching Lives: <span style={{ transform: `translateY(${scrollPosition}px)`}} className="col-secondary scrolling-text">{list[0].name}</span></motion.h2>
                    <motion.h2 className="col-container-font mb-0 flow show-mb h1-size"> Business, Enriching Lives:</motion.h2>
                    <motion.h2 className="col-container-font mb-0 flow show-mb h1-size mt-1"><span style={{ transform: `translateY(${scrollPosition}px)`}} className="col-secondary scrolling-text">{list[0].name}</span></motion.h2>
                    <motion.p className="font font-weight-3 m-t-16 m-b-30 p-size">
                        Enjoy a seamless journey from recruitment to retirement, backed by unparalleled user experience, robust architecture, flexible and affordable pricing.
                    </motion.p>
                    <motion.div className="res-btn">
                      <SignupButton />
                    </motion.div>
                </motion.div>
                <div className="d-flex flex-column justify-content-center align-items-center position-relative w-45 second">
                  <motion.div className="z-index-1" initial={{opacity: 0}} animate={{opacity: [0, 0.2, 0.3, 0.4, 0.6, 0.8, 0.9, 1]}} transition={{duration: 2, delay: 2, ease: 'easeOut'}}>
                    <Charts/>
                  </motion.div>
                  <div className="position-absolute bgnd z-index-0 rounded-circle">
                  </div>
                  <motion.div variants={childTop} initial="hidden" animate="visible" className="position-absolute top-logo">
                      <TopW style={{opacity:0.7}}/>
                  </motion.div>
                  <motion.div variants={childBottom} initial="hidden" animate="visible" className="position-absolute p-0 bottom-logo">
                      <BottomW style={{opacity:0.7}}/>
                  </motion.div>
                  <motion.div variants={child2} initial="hidden" animate="visible" className="position-absolute p-0 z-index-0">
                      <Left style={{opacity:0.7}}/>
                  </motion.div>
                </div>
                <div className="custom-shape-divider-bottom-1715923248">
                  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                      <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                  </svg>
                </div>
            </div>
        
        {/* <motion.div variants={child1} initial="hidden" animate="visible" className="position-absolute left-0 bottom-0 p-0 ev">
          <Right style={{rotate:"10deg"}} className="ev" />
        </motion.div> */}
    </motion.div>    
    )
}

export default Bussiness
