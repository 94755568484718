import { useContext, useEffect, useState } from "react"

import { MenuContext } from "../../../app"
import {ReactComponent as Astronut} from '../../../assets/logos/astronut-product.svg'

import './index.scss'

const ProductMenuCard = () => {

  const card = [
    {id:1, for:'HR', head:<p className='m-0'>Transform Your HR Management Today with <span className='col-secondary'> Walkzero</span></p>},
    {id:2, for:'Payroll', head:<p className='m-0'>Simplify Payroll, Focus on Growth — <span className='col-secondary'>Leave It to Us!</span></p>},
  ]

  const [cardHead, setCardHead] = useState(card[0].head)

  const props = useContext(MenuContext)
  const { productMenuCard } = props

  useEffect(()=>{
    if (productMenuCard === 'HR'){
      setCardHead(card[0].head)
    }
    else if(productMenuCard === 'Payroll'){
      setCardHead(card[1].head)
    }
  },[productMenuCard])

  return (
    <div className="product-menu-card d-between flex-row align-self-center m-t-10">
        <div className="cont-card d-flex flex-column">
            <p className="col-quaternary font-weight-5 text-12 m-b-0 m-t-0 text-uppercase cent">
                A cloud HRMS platform
            </p>
            <h3 className="col-container-font h2-size cent center">
                {/* Transform Your HR Management Today with <span className='col-secondary'> Walkzero</span> */}
                {cardHead}
            </h3>
        </div>
        <Astronut height={140} className='hide-mb' />
    </div>
  )
}

export default ProductMenuCard