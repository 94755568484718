import {ReactComponent as Scooter} from '../../../assets/logos/payroll-delivery.svg'
import './index.scss'

const Delivery = () => {

    return(
        <>
        <div className='pink-bg  d-flex flex-row justify-content-center align-items-center bg-col-new-light-green position-relative'>
            <div className='payroll-self d-flex flex-row justify-content-between align-items-center'>
                <div className='d-flex flex-column justify-content-center align-items-start delivery-quotes'>
                    <p className='sora font-weight-6 text-32'>"Get <span className='col-secondary'>started</span> before your order arrives with <span className='col-secondary'>Walkzero</span> Payroll Software."</p>
                    <ul className='lexend-deca font-weight-6 d-flex flex-row delivery-ul'>
                        <li>Create Your Account</li>
                        <li>Onboard Your Team</li>
                        <li>Run Payroll</li>
                    </ul>
                    <p className='lexend-deca'>With everything set, process payroll in just 45 minutes—quick and hassle-free!</p>
                </div>
                {/* <div className='scooter'> */}
                    <Scooter className='scooter' style={{height:"300px",marginTop:"-80px",marginBottom:"-20px"}}/>
                {/* </div> */}
            </div>
        </div>
        </>
    )
}
export default Delivery