import {ReactComponent as GreenLine} from '../../../../assets/logos/green-line.svg'
import {useAnimation, motion} from "framer-motion"
import { useInView } from "react-intersection-observer";
import { useEffect } from 'react';
import { container, child4 } from "../../../../variants"

import './index.scss'

const attendLine = <GreenLine id='yellowLine'/>

const merits = [
    {id:"merit1", icon:attendLine, title:"Customized Leave Types", cont:"Organizations experience higher employee satisfaction and better compliance with labor laws."},
    {id:"merit2", icon:attendLine, title:"Holiday Coordination", cont:"Organizations experience higher employee satisfaction and better compliance with labor laws."},
    {id:"merit3", icon:attendLine, title:"Optimized Approval Workflows", cont:"Organizations benefit from faster approval processes and reduced administrative workload."}
]

const LeaveMerits = () => {

    const ctrls = useAnimation();

    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: false,
    });

    useEffect(() => {
        if (inView) {
            ctrls.start("visible");
        }
        if (!inView) {
            ctrls.start("hidden");
        }
    }, [ctrls, inView])

  return (
    <motion.div ref={ref} variants={container} initial="hidden" animate={ctrls} id="Merits" className='leave-merits d-flex flex-row justify-content-center'>
        <motion.ul className='pro-center d-flex flex-row justify-content-between align-items-start'>
            {merits.map((each)=> {
                return <motion.li className='d-flex flex-column justify-content-center merits-cont' key={each.id}>
                    <div className='d-flex flex-row'>
                        <span className='green-line'>{each.icon}</span>
                        <motion.h3 variants={child4} className='text-18 font-weight-600 merit-head'>{each.title}</motion.h3>
                    </div>
                    <p className=' font-weight-400 p-size merit-para'>{each.cont}</p>
                </motion.li>
            })}
        </motion.ul>
    </motion.div>
  )
}
export default LeaveMerits