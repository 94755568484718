import { Link } from "react-router-dom"
import {motion} from "framer-motion"
import { useEffect, useContext } from "react";
import { MenuContext } from "../../../app";

import './index.scss';

const MobProducts = () =>{

    const props = useContext(MenuContext);

    const {isMobProductOpen, setIsMobProductOpen, modules} = props

    useEffect(() => {
        const handleResize = () => {
          if (window.innerWidth > 860) {
            setIsMobProductOpen(false)
          }
        };
    
        window.addEventListener('resize', handleResize);

        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);


    return(
        <>
        {isMobProductOpen && <motion.div initial={{y:"100%",opacity:0}} animate={{y:0,opacity:1, transition:{ type:"tween", duration:0.5}}} className="d-flex flex-column align-items-center justify-content-start mob-hr-products">
            <ul className="mob-hr-prod-ul">
                {modules.map((each:any)=> {
                    return <Link key={each.id} to={each.to} style={{backgroundColor:each.bg}} className="mob-hr-link rounded-2">
                        <motion.li whileHover={{backgroundColor:each.bg}} onClick={()=>setIsMobProductOpen(false)} className="mob-hr-prod-list d-flex flex-column p-y-15 p-x-15 rounded-2">
                            <motion.p className='d-flex flex-row align-items-center m-t-0'>{each.icon}<motion.span whileHover={{color:each.clr}} className='m-l-10 menu-name'>{each.name}</motion.span></motion.p>
                            <p className="m-b-0 m-t-0">{each.content}</p>
                        </motion.li>
                    </Link>}
                )}
            </ul>      
        </motion.div>
        }
        </>
    )

}

export default MobProducts 