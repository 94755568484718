import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { TailSpin } from "react-loader-spinner"

import SignupButton from '../../layout/button';
import {ReactComponent as Astronut} from '../../../assets/logos/payroll-banner-astronut.svg'
import AutoPlay from '../../layout/industry-slide';
import { data, settings } from '../../landing-page/home';
import {ReactComponent as Left} from "../../../assets/logos/logo-left.svg"
import {ReactComponent as TopW} from "../../../assets/logos/top-w-curve.svg"
import {ReactComponent as BottomW} from "../../../assets/logos/bottom-w-curve.svg"
import Delivery from '../payroll-delivery';
import SubMenu from '../payroll-menus';
import Faqs from '../../landing-page/faqs';


import './index.scss';
import Card from '../../landing-page/card';
import Modules from '../../product/hr-hub/modules'
import HrTasks from '../../product/hr-hub/hr-tasks';
import PayrollProcess from '../payroll-process';
import HeroSection from '../banner-components/banner-main';


type FaqData = {
  faq:{
      question:string 
      answer:string 
      order: number
  }[]
}

const initialData = {
  faq:[]
}

const PayrollMain = () => {

  const [faqs, setFaqs] = useState<FaqData>(initialData);
  const [isLoading, setIsLoading] = useState(true)

  const getFaqs = async() => {
      setIsLoading(true)
      const url = `${process.env.REACT_APP_BASE_URL}/faq?menu=payroll`;
      const options = {
          method: 'GET',
          headers: {
              'content-type': 'application/json',
          }
      }

      try{
          const response = await fetch(url, options)
          if(response.ok){
              setIsLoading(false)
              const faqs = await response.json()
              setFaqs(faqs.data)
          }
      }
      catch(error:any){
          console.error(`error in fetching faqs: ${error.message}`)
      }
  }

  useEffect(() => {
      getFaqs()
  }, [])
  
  const Top = {
    visible: {
      x:0,
      y:0,
      transition: {
        duration: 1,
        delay: 1,
        type:'tween'
      }
    },
    hidden: {
      x:0,
      y:-500,
    }
  }

  const Bottom = {
    visible: {
      x:0,
      y:0,
      transition: {
        duration: 1,
        delay: 1,
        type:'tween'
      }
    },
    hidden: {
      x:0,
      y:180,
    }
  }

  const child2w = {
    visible: {
      x:0,
      y:0,
      rotate:0,
      transition: {
        duration: 1.5,
        type:'tween'
      }
    },
    hidden: {
      x:-1700,
      y:350,
      rotate:90,
    }
  }
    return(
      <>
        <div className='position-relative payroll-overall'>
          <div className='payroll-bg d-flex flex-column align-items-center justify-content-center bg-col-new-light-green'>
              <div className='payroll d-flex flex-row align-items-center justify-content-between'>
                  <div className='payroll-main-1 d-flex flex-column align-items-start justify-content-center'>
                      <p className='sora col-quaternary text-12 font-weight-5 m-0'>#Payroll #PayrollAutomation #StatutoryCompliance #StayCompliant</p>
                      <p className='sora text-32 m-0'>From <span className='col-secondary'>Hire to Pay</span>: One Platform, Zero Hassle.</p>
                      <p className='lexend-deca mb-2 font-weight-3 text-14'>Streamline your payroll process—automate salary disbursement, tax filings, and compliance with ease, all from one user-friendly platform.</p>
                      <SignupButton/>
                      <Astronut className='banner-astro mt-3'/>
                  </div>
                  <div className='payroll-main-2 d-flex flex-column align-items-center justify-content-center position-relative'>
                      <motion.div className="z-index-1 w-100" initial={{opacity: 0}} animate={{opacity: [0, 0.2, 0.3, 0.4, 0.6, 0.8, 0.9, 1]}} transition={{duration: 2, delay: 2, ease: 'easeOut'}}>
                        <HeroSection className='w-100 z-index-1'/>
                      </motion.div>
                      <div className='green-bottom'></div>
                      <motion.div variants={Top} initial="hidden" animate="visible" className="position-absolute payroll-top">
                          <TopW id='Payroll-top' style={{opacity:0.6}}/>
                      </motion.div>
                      <motion.div variants={Bottom} initial="hidden" animate="visible" className="position-absolute p-0 payroll-bottom">
                          <BottomW id='Payroll-bottom' style={{opacity:0.6}}/>
                      </motion.div>
                      <motion.div variants={child2w} initial="hidden" animate="visible" className="position-absolute p-0 z-index-0">
                          <Left id='LeftPayroll' style={{opacity:0.6}}/>
                      </motion.div>
                  </div>
              </div>
              <div className="custom-shape-divider-bottom-1715923248">
                  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                      <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                  </svg>
              </div>
          </div>
          <div className='d-flex flex-row justify-content-center align-items-center'>
            <AutoPlay data={data} settings={settings} />
          </div>
          <Delivery/> 
          <PayrollProcess/>
          <div className='d-flex flex-row justify-content-center align-items-center w-100'>
            <div className='d-flex flex-row justify-content-start align-self-center w-90 one-finance'>
              <div className='d-flex flex-column justify-content-center align-items-start one-finance-wrapper'> <p className='sora text-32 font-weight-6'>The all in <span className='col-secondary'>one finance</span> platform you've been looking for</p> <p className='lexend-deca text-14 font-weight-3'>Build teams and expand into new markets with a leading global payroll provider. Reduce payroll admin to offer an exceptional and consistent experience across multiple countries and growing headcounts.</p> </div>
            </div>
          </div>
          <SubMenu/>
          {isLoading? <div className="d-flex flex-column align-items-center justify-content-center m-t-100 m-b-100"><TailSpin  visible={true} height="30" width="30" color="#f45600" ariaLabel="tail-spin-loading" radius="1" /></div>:<Faqs faqs={faqs} setFaqs={setFaqs} />}
          <div className='d-flex flex-column align-items-center justify-content-center'>
            <HrTasks/>
          </div>
          <div className='d-flex flex-row align-items-center justify-content-center pt-5 pb-5 w-100'>
            <Modules/>
          </div>
          <Card/>
        </div>
        </>
    )
}
export default PayrollMain