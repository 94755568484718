import { SVGProps, useRef } from "react"
import { motion, useInView } from "framer-motion";

import '../../../../styles/fonts.scss';

const Insights = (props: SVGProps<SVGSVGElement>) => {

    const ref = useRef(null);
    const isInView = useInView(ref, { once: false , amount: 0.8});

    return(
    <>
     <svg
    // width={538}
    // height={310}
    viewBox="0 0 538 310"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Group 1321314899">
      <g id="Group 1321314897">
        <g id="Rectangle 5996" filter="url(#filter0_d_1_46)">
          <rect x={4} y={2} width={530} height={302} rx={24} fill="white" />
        </g>
        <g id="Group 1321314898">
          <text
            id="Pay report History"
            fill="#3C3C3C"
            xmlSpace="preserve"
            style={{
              whiteSpace: "pre",
            }}
            fontFamily="Lexend-Deca"
            fontSize={14}
            fontWeight={500}
            letterSpacing="0em"
          >
            <tspan x={28} y={32.25}>
              {"Pay report History"}
            </tspan>
          </text>
          <g id="Group 1321314891">
            <text
              id="$ 2510,00"
              fill="#5D45DB"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Lexend-Deca"
              fontSize={12}
              fontWeight={500}
              letterSpacing="0em"
            >
              <tspan x={445} y={90}>
                {"$ 2510,00"}
              </tspan>
            </text>
            <text
              id="$ 2120,00"
              fill="#FF696D"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Lexend-Deca"
              fontSize={12}
              fontWeight={500}
              letterSpacing="0em"
            >
              <tspan x={455} y={135}>
                {"$ 2120,00"}
              </tspan>
            </text>
            <text
              id="$ 200,00"
              fill="#6CB9AD"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Lexend-Deca"
              fontSize={12}
              fontWeight={500}
              letterSpacing="0em"
            >
              <tspan x={466} y={176}>
                {"$ 200,00"}
              </tspan>
            </text>
            <text
              id="$ 175,00"
              fill="#EDC161"
              xmlSpace="preserve"
              style={{
                whiteSpace: "pre",
              }}
              fontFamily="Lexend-Deca"
              fontSize={12}
              fontWeight={500}
              letterSpacing="0em"
            >
              <tspan x={445} y={213}>
                {"$ 175,00"}
              </tspan>
            </text>
          </g>
          <g id="Frame 1618872809">
            <g id="Group 1321314896">
              <text
                id="Production Dep"
                fill="#3C3C3C"
                fillOpacity={0.8}
                xmlSpace="preserve"
                style={{
                  whiteSpace: "pre",
                }}
                fontFamily="Lexend-Deca"
                fontSize={12}
                fontWeight={300}
                letterSpacing="0em"
              >
                <tspan x={55} y={59}>
                  {"Production Dep"}
                </tspan>
              </text>
              <rect
                id="Rectangle 5997"
                x={28}
                y={51}
                width={23}
                height={7}
                rx={3.5}
                fill="#5D45DB"
              />
            </g>
            <g id="Group 1321314895">
              <text
                id="Quality Dep"
                fill="#3C3C3C"
                fillOpacity={0.8}
                xmlSpace="preserve"
                style={{
                  whiteSpace: "pre",
                }}
                fontFamily="Lexend-Deca"
                fontSize={12}
                fontWeight={300}
                letterSpacing="0em"
              >
                <tspan x={187} y={59}>
                  {"Quality Dep"}
                </tspan>
              </text>
              <rect
                id="Rectangle 5997_2"
                x={160}
                y={51}
                width={23}
                height={7}
                rx={3.5}
                fill="#FF696D"
              />
            </g>
            <g id="Group 1321314894">
              <text
                id="Maintenance Dep"
                fill="#3C3C3C"
                fillOpacity={0.8}
                xmlSpace="preserve"
                style={{
                  whiteSpace: "pre",
                }}
                fontFamily="Lexend-Deca"
                fontSize={12}
                fontWeight={300}
                letterSpacing="0em"
              >
                <tspan x={298} y={59}>
                  {"Maintenance Dep"}
                </tspan>
              </text>
              <rect
                id="Rectangle 5998"
                x={271}
                y={51}
                width={23}
                height={7}
                rx={3.5}
                fill="#6CB9AD"
              />
            </g>
            <g id="Group 1321314893">
              <text
                id="Security Dep"
                fill="#3C3C3C"
                fillOpacity={0.8}
                xmlSpace="preserve"
                style={{
                  whiteSpace: "pre",
                }}
                fontFamily="Lexend-Deca"
                fontSize={12}
                fontWeight={300}
                letterSpacing="0em"
              >
                <tspan x={441} y={59}>
                  {"Security Dep"}
                </tspan>
              </text>
              <rect
                id="Rectangle 5999"
                x={414}
                y={51}
                width={23}
                height={7}
                rx={3.5}
                fill="#EDC161"
              />
            </g>
          </g>
          <path
            id="Vector 526"
            d="M518 264L20 264"
            stroke="black"
            strokeOpacity={0.12}
            strokeWidth={0.8}
          />
          <text
            id="July"
            fill="#3C3C3C"
            fillOpacity={0.8}
            xmlSpace="preserve"
            style={{
              whiteSpace: "pre",
            }}
            fontFamily="Lexend-Deca"
            fontSize={14}
            fontWeight={300}
            letterSpacing="0em"
          >
            <tspan x={20} y={284.25}>
              {"July"}
            </tspan>
          </text>
          <text
            id="Aug"
            fill="#3C3C3C"
            fillOpacity={0.8}
            xmlSpace="preserve"
            style={{
              whiteSpace: "pre",
            }}
            fontFamily="Lexend-Deca"
            fontSize={14}
            fontWeight={300}
            letterSpacing="0em"
          >
            <tspan x={114} y={284.25}>
              {"Aug"}
            </tspan>
          </text>
          <text
            id="Sep"
            fill="#3C3C3C"
            fillOpacity={0.8}
            xmlSpace="preserve"
            style={{
              whiteSpace: "pre",
            }}
            fontFamily="Lexend-Deca"
            fontSize={14}
            fontWeight={300}
            letterSpacing="0em"
          >
            <tspan x={208} y={284.25}>
              {"Sep"}
            </tspan>
          </text>
          <text
            id="Oct"
            fill="#3C3C3C"
            fillOpacity={0.8}
            xmlSpace="preserve"
            style={{
              whiteSpace: "pre",
            }}
            fontFamily="Lexend-Deca"
            fontSize={14}
            fontWeight={300}
            letterSpacing="0em"
          >
            <tspan x={301} y={284.25}>
              {"Oct"}
            </tspan>
          </text>
          <text
            id="Nov"
            fill="#3C3C3C"
            fillOpacity={0.8}
            xmlSpace="preserve"
            style={{
              whiteSpace: "pre",
            }}
            fontFamily="Lexend-Deca"
            fontSize={14}
            fontWeight={300}
            letterSpacing="0em"
          >
            <tspan x={392} y={284.25}>
              {"Nov"}
            </tspan>
          </text>
          <text
            id="Dec"
            fill="#3C3C3C"
            fillOpacity={0.8}
            xmlSpace="preserve"
            style={{
              whiteSpace: "pre",
            }}
            fontFamily="Lexend-Deca"
            fontSize={14}
            fontWeight={300}
            letterSpacing="0em"
          >
            <tspan x={486} y={284.25}>
              {"Dec"}
            </tspan>
          </text>
        </g>
      </g>
      <motion.path
       ref={ref} 
       initial={{ strokeDasharray: "0 480" }} 
       animate={ isInView?{ strokeDasharray: "480 480" }:{}} 
       transition={{ duration: 1.5, ease: "easeInOut", type:"tween", delay:.5 }} 
        id="blue"
        d="M20 255L114.548 231.087L258.711 157.883L315.815 150.563L445 92"
        stroke="#5D45DB"
      />
      <motion.path
       ref={ref} 
       initial={{ strokeDasharray: "0 480" }} 
       animate={ isInView?{ strokeDasharray: "480 480" }:{}} 
       transition={{ duration: 1.5, ease: "easeInOut", type:"tween", delay:1 }} 
        id="red"
        d="M19 255L200.733 220.5L226.833 206L291.6 196.5L359.267 161L405.667 152L454 135"
        stroke="#FF696D"
      />
      <motion.path
       ref={ref} 
       initial={{ strokeDasharray: "0 480" }} 
       animate={ isInView?{ strokeDasharray: "480 480" }:{}} 
       transition={{ duration: 1.5, ease: "easeInOut", type:"tween", delay:1.5 }} 
        id="green"
        d="M20 256L73.0744 250.495L171.021 232.286L234.228 226.781L313.839 219.582L461 173"
        stroke="#6CB9AD"
      />
      <motion.path
       ref={ref} 
       initial={{ strokeDasharray: "0 480" }} 
       animate={ isInView?{ strokeDasharray: "480 480" }:{}} 
       transition={{ duration: 1.5, ease: "easeInOut", type:"tween", delay:2 }} 
        id="yellow"
        d="M19 255L140.223 239.97L249.468 231.545L444 209"
        stroke="#EDC161"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1_46"
        x={0}
        y={0}
        width={538}
        height={310}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={2} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0705882 0 0 0 0 0.658824 0 0 0 0 0.658824 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1_46"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1_46"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
    </>
    )
}
export default Insights
